@import "abstracts/variables";
@import "components/_navbar.scss";
@import "components/_tile.scss";
// Bien import toastr avant le theme
@import "toastr";
@import "~6tm-admin-theme/src/scss/main";

ul.list-group {
    li {
        list-style-type: none;

        a {
            text-decoration: none;
        }
    }
}

.field-validation-error {
    color: #dc3545;
    font-size: 85.71428571%;
    margin-top: 0.25rem;
    width: 100%;
}