﻿.fa-green {
    color: green;
}

.fa-red {
    color: red;
}

.tiles-grid {
    position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fit, 70px);
    grid-template-rows: repeat(auto-fit, 70px);
    grid-gap: 10px;
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 400;

    a, a:hover {
        color: inherit;
        text-decoration: none;
    }
}

.tiles-grid .tile-small {
    grid-column: span 1;
    grid-row: span 1;
}

.tiles-grid .tile-medium {
    grid-column: span 2;
    grid-row: span 2;
}

.tiles-grid .tile-wide {
    grid-column: span 4;
    grid-row: span 2;
}

.tiles-grid .tile-mwide {
    grid-column: span 3;
    grid-row: span 2;
}

.tiles-grid .tile-large {
    grid-column: span 4;
    grid-row: span 4;
}

.tile {
    width: 150px;
    height: 150px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    display: block;
    background-color: #3f51b5;
    color: #ffffff;
}

.tile.tile-small {
    width: 70px !important;
    height: 70px !important;
}



.tile.tile-medium {
    width: 150px !important;
    height: 150px !important;
}

.tile.tile-mwide {
    width: 230px !important;
    height: 150px !important;
}

.tile.tile-wide {
    width: 310px !important;
    height: 150px !important;
}

.tile.tile-large {
    width: 310px !important;
    height: 310px !important;
}

.tile .icon {
    max-width: 33%;
    height: 33%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
    font-size: 50px;
    line-height: 50px;
}

.tile .tile-body {
    position: absolute;
    left: 0;
    right: 0;
    padding: 10px;
}

.tile .branding-bar {
    height: 32px;
    line-height: 32px;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 0 10px 5px;
    font-size: 0.875rem;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    z-index: 2;
}

.tile .badge-bottom {
    position: absolute;
    display: block;
    padding: 4px 8px;
    font-size: 12px;
    text-align: center;
    background-color: rgba(29, 29, 29, 0.1);
    z-index: 2;
    right: 10px;
    bottom: 0;
}

.tile .badge-top {
    position: absolute;
    display: block;
    padding: 4px 8px;
    font-size: 12px;
    text-align: center;
    background-color: rgba(29, 29, 29, 0.1);
    z-index: 2;
    top: 10px;
    right: 10px;
}

.tile-small .branding-bar {
    display: none;
}

.tile-small .icon {
    max-width: 50%;
    height: 50%;
    font-size: 35px;
    line-height: 35px;
}

.tile-large .icon {
    font-size: 102px;
    line-height: 102px;
}

.tile-light {
    background-color: white;
    border: 1px solid lightgrey;
    color: black;
}

.text-large {
    font-size: 14px !important;
    font-variant: small-caps;
}

.text-xlarge {
    font-size: 20px !important;
}

.tile .tile-primary {
    color: black;
    background-color: #3cbf33;
}

.tile.tile-light .tile-primary {
    border: 1px solid #3cbf33;
}
